import React, { useEffect, useState } from 'react';
import AddUserActivity from './AddUserActivity';
import PreLoader from './Preloader';
import AppBarOffset from './AppBarOffset';
import {Link} from "react-router-dom";
import {
  AppBar,
  Button,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import {clearTokens} from "../session";
import {auth0} from "../environment";
import Grid2 from '@mui/material/Grid2';

function UserView(props) {
    const logout = () => {
        clearTokens();
        window.location.href = `${auth0.host}/v2/logout?client_id=${auth0.clientId}&returnTo=${auth0.redirect}`;
    }

    var theme = useTheme();

    var activityList = (<></>);
    var activeShares = (<></>);
    var pendingInvitations = (<></>);

    const {onFetchUserInfo} = props;

    const [addPanelOpen, setAddPanelOpen] = useState(false);

    useEffect(() => {
        onFetchUserInfo();
    }, [onFetchUserInfo]);


    if (!props.userInfo || props.userInfo.loading) {
        return (<PreLoader />)
    }

    let ua = props.userInfo.userActivities;
    let canCreateNewActivity = true;

  let hasShares = false;
  let hasActivities = false;
  let hasPendingInvitations = false;

    if (ua && ua.items) {
        let listItems = ua.items.map(({ PubID, Name }) => {
            return <ListItem divider={true} component={Link} to={`/activities/${PubID}`}
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={(e) => {
                        e.preventDefault(); // We're inside a link, so we need to prevent that click redirecting us
                        window.confirm(`Are you sure you want to delete "${Name}"?`) && props.onDeleteUserActivity(PubID)}}
                    >
                        <DeleteIcon sx={{color: theme.palette.text.icon}}/>
                    </IconButton>
                }
              >
                  <ListItemText
                    primary={Name}
                    sx={{color: theme.palette.text.primary}}
                  />
              </ListItem>
            }
        );

      hasActivities = listItems.length > 0;

        activityList = <List>{listItems}</List>;

        if(activityList.length >= ua.limit){
            canCreateNewActivity = false;
        }
    }

    let shares = props.userInfo.sharedWithMe;


    if (shares && shares.items) {
        let invitations = shares.items.filter((x) => x.Pending).map(({ PubID, ReadOnly, UserActivity }) => {
              return <ListItem
                        divider={true}
                        sx={{backgroundColor:theme.palette.warning.light}}
                        secondaryAction={
                            <IconButton edge="end" aria-label="Accept" onClick={(e) => props.onAcceptShare(PubID)}>
                                <CheckIcon sx={{color: theme.palette.warning.contrastText}}/>
                            </IconButton>
                        }>
                          <ListItemText
                            primary={<>
                                <strong>{UserActivity.User.Email}</strong> has requested to share <strong>{UserActivity.Name}</strong> {ReadOnly ? "(read only)" : ""} with you.</>
                            }
                            sx={{color: theme.palette.warning.contrastText}}
                          />
                    </ListItem>
          });

      hasPendingInvitations = invitations.length > 0;
        pendingInvitations = <List>{invitations}</List>;

        let shareItems = shares.items.filter((x) => !x.Pending).map(({ UserActivity }) => {
              return <ListItem divider={true} component={Link} to={`/activities/${UserActivity.PubID}`}
                               secondaryAction={<Typography variant="p" sx={{color:theme.palette.common.white}}>{UserActivity.Name}</Typography>}
              >
                  <ListItemText
                    primary={UserActivity.User.Email}
                    sx={{color: theme.palette.text.primary}}
                  />
              </ListItem>
          }
        );


        hasShares = shareItems.length > 0;
        activeShares = <List>{shareItems}</List>
    }

    if (addPanelOpen) {
        return <AddUserActivity onAdd={props.onAddUserActivity}
        defaultMeasure="Distance" 
        defaultMeasureName="Kms"
        defaultPreTimelineDays="28"
        defaultPostTimelineDays="28"
        onClose={() => setAddPanelOpen(false)} />
    }

    return (<>
            <AppBar position="fixed" sx={{backgroundColor: theme.palette.common.black}}>
                <Toolbar>
                        <img src="../assets/img/x-tracked-logo-small.png" style={{height:'18px', marginRight: '20px'}} alt="logo" />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                        Activities
                    </Typography>
                  <div>
                      <IconButton
                        size="medium"
                        edge="end"
                        aria-label="Logout"
                        aria-controls="menu-appbar"
                        onClick={logout}
                        color="inherit"
                      >
                          <LogoutIcon />
                      </IconButton>
                  </div>
                </Toolbar>
            </AppBar>
            <AppBarOffset />
            <Grid2 container size={12}>
              <Grid2 size={{xs:12, lg:8}} >
                {hasPendingInvitations && <div style={{padding: '10px 10px 0px 10px'}}>
                  {pendingInvitations}
                </div>}
                {
                  hasShares && <><div style={{textAlign: 'left', paddingLeft: '15px', paddingTop: '10px'}}><Typography variant={"h6"} color={"primary.light"} sx={{fontWeight: 'normal'}}>Shared with me</Typography></div>

                    {activeShares}</>
                }
                {!hasActivities || 1==2 ?
                  <Grid2 container spacing={3}>
                    <Grid2 size={12}>
                      <Typography variant={"h3"} color={"primary"} sx={{padding:'20px', fontSize: '2rem'}}>CREATE YOUR FIRST ACTIVITY</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                      <img src={"/assets/img/graph_example.png"} alt={"Graph example"} style={{maxWidth: '500px', width: '100%', paddingBottom: '60px'}} />
                    </Grid2>
                  </Grid2> :
                  <>
                    <div style={{textAlign: 'left', paddingLeft: '15px', marginTop: hasShares ? '30px' : '0px'}}><Typography variant={"h6"} color={"primary.light"} sx={{fontWeight: 'normal'}}>My activities</Typography></div>
                    {activityList}
                  </>
                }

                { // Users can only create new activities if they have not reached the limit
                  canCreateNewActivity ?
                    <Fab variant="extended" size="large" color="primary" onClick={() => setAddPanelOpen(true)} sx={{mt:3}}>
                      <AddCircleIcon sx={{ mr: 1 }} />
                      New activity
                    </Fab>
                    :
                    <Button variant="contained" size={"large"} disabled>
                      You have reached your allowed limit of activities ({ua.limit}). <br />Contact X-Tracked to increase your limit.
                    </Button>
                }
              </Grid2>
            </Grid2>
    </>)
}

export default UserView;
