
import React, {useEffect, useState} from 'react';
import { getWholeDay } from '../graphCalculator';
import {Button, TextField, Typography} from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey, blueGrey } from "@mui/material/colors";
import {SubHeading} from "./Subheading";
import CloseIcon from '@mui/icons-material/Close';
import Grid2 from "@mui/material/Grid2";

const StyledButtonTop = styled(Button)(({ theme }) => ({
    backgroundColor: grey[600],
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    borderColor: blueGrey[200],
    borderRadius: '10px 10px 0px 0px',
    marginRight: '3px',
    "&:hover": {
        backgroundColor: grey[500],
        borderColor: blueGrey[300]
    }
}));

const StyledButtonBottom = styled(Button)(({ theme }) => ({
    backgroundColor: grey[600],
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    borderColor: blueGrey[200],
    borderRadius: '0px 0px 10px 10px',
    marginRight: '3px',
    "&:hover": {
        backgroundColor: grey[500],
        borderColor: blueGrey[300]
    }
}));

function GoalPlanner(props) {
    let {
        currentChronic,
    } = props;


    const [planStartingLoad, setPlanStartingLoad] = useState(currentChronic);
    const [planTargetLoad, setPlanTargetLoad] = useState(Math.round(currentChronic));
    let defaultTargetDate = new Date();
    defaultTargetDate.setDate(defaultTargetDate.getDate() + 28);
    const [planTargetDate, setPlanTargetDate] = useState(getWholeDay(defaultTargetDate));

    const [planPracticeDaysSun, setPlanPracticeDaysSun] = useState(0);
    const [planPracticeDaysMon, setPlanPracticeDaysMon] = useState(0);
    const [planPracticeDaysTue, setPlanPracticeDaysTue] = useState(0);
    const [planPracticeDaysWed, setPlanPracticeDaysWed] = useState(0);
    const [planPracticeDaysThu, setPlanPracticeDaysThu] = useState(0);
    const [planPracticeDaysFri, setPlanPracticeDaysFri] = useState(0);
    const [planPracticeDaysSat, setPlanPracticeDaysSat] = useState(0);

    const [planCompDaysSun, setPlanCompDaysSun] = useState(0);
    const [planCompDaysMon, setPlanCompDaysMon] = useState(0);
    const [planCompDaysTue, setPlanCompDaysTue] = useState(0);
    const [planCompDaysWed, setPlanCompDaysWed] = useState(0);
    const [planCompDaysThu, setPlanCompDaysThu] = useState(0);
    const [planCompDaysFri, setPlanCompDaysFri] = useState(0);
    const [planCompDaysSat, setPlanCompDaysSat] = useState(0);

    const onPlanStartLoadChange = (val) => {
        setPlanStartingLoad(val);
    }

    const onPlanTargetLoadChange = (val) => {
        setPlanTargetLoad(val);
    }

    const onPlanTargetDateChange = (val) => {
        setPlanTargetDate(val);
    }

    const onPlanDaysOfWeekChange = (val, day, type) => {
        val = parseInt(val);
        if (type === 'practice') {
            switch (day) {
                case 'Sun':
                    setPlanPracticeDaysSun((planPracticeDaysSun < 3) ? (planPracticeDaysSun + 1) : 0);
                    break;
                case 'Mon':
                    setPlanPracticeDaysMon((planPracticeDaysMon < 3) ? (planPracticeDaysMon + 1) : 0);
                    break;
                case 'Tue':
                    setPlanPracticeDaysTue((planPracticeDaysTue < 3) ? (planPracticeDaysTue + 1) : 0);
                    break;
                case 'Wed':
                    setPlanPracticeDaysWed((planPracticeDaysWed < 3) ? (planPracticeDaysWed + 1) : 0);
                    break;
                case 'Thu':
                    setPlanPracticeDaysThu((planPracticeDaysThu < 3) ? (planPracticeDaysThu + 1) : 0);
                    break;
                case 'Fri':
                    setPlanPracticeDaysFri((planPracticeDaysFri < 3) ? (planPracticeDaysFri + 1) : 0);
                    break;
                case 'Sat':
                    setPlanPracticeDaysSat((planPracticeDaysSat < 3) ? (planPracticeDaysSat + 1) : 0);
                    break;
                default:
                    console.error('Invalid day');
            }
        }
        else if (type === 'competitive') {
            switch (day) {
                case 'Sun':
                    setPlanCompDaysSun((planCompDaysSun < 3) ? (planCompDaysSun + 1) : 0);
                    break;
                case 'Mon':
                    setPlanCompDaysMon((planCompDaysMon < 3) ? (planCompDaysMon + 1) : 0);
                    break;
                case 'Tue':
                    setPlanCompDaysTue((planCompDaysTue < 3) ? (planCompDaysTue + 1) : 0);
                    break;
                case 'Wed':
                    setPlanCompDaysWed((planCompDaysWed < 3) ? (planCompDaysWed + 1) : 0);
                    break;
                case 'Thu':
                    setPlanCompDaysThu((planCompDaysThu < 3) ? (planCompDaysThu + 1) : 0);
                    break;
                case 'Fri':
                    setPlanCompDaysFri((planCompDaysFri < 3) ? (planCompDaysFri + 1) : 0);
                    break;
                case 'Sat':
                    setPlanCompDaysSat((planCompDaysSat < 3) ? (planCompDaysSat + 1) : 0);
                    break;
                default:
                    console.error('Invalid day');
            }
        }
    }



    useEffect(() => {
        const makePlan = () => {
            var today = new Date();
            let starting = parseInt(planStartingLoad);
            let target = planTargetLoad;
            let targetDate = new Date(planTargetDate);

            let weeklyPlanned = planPracticeDaysSun + planPracticeDaysMon + planPracticeDaysTue + planPracticeDaysWed + planPracticeDaysThu + planPracticeDaysFri + planPracticeDaysSat;
            let weeklyComp = planCompDaysSun + planCompDaysMon + planCompDaysTue + planCompDaysWed + planCompDaysThu + planCompDaysFri + planCompDaysSat;
            let totalWeekly = weeklyPlanned + weeklyComp;

            let practiceDays = {};
            let competitiveDays = {};
            if (planPracticeDaysSun > 0) practiceDays['Sun'] = Math.round(planPracticeDaysSun * 100 / totalWeekly) / 100;
            if (planPracticeDaysMon > 0) practiceDays['Mon'] = Math.round(planPracticeDaysMon * 100 / totalWeekly) / 100;
            if (planPracticeDaysTue > 0) practiceDays['Tue'] = Math.round(planPracticeDaysTue * 100 / totalWeekly) / 100;
            if (planPracticeDaysWed > 0) practiceDays['Wed'] = Math.round(planPracticeDaysWed * 100 / totalWeekly) / 100;
            if (planPracticeDaysThu > 0) practiceDays['Thu'] = Math.round(planPracticeDaysThu * 100 / totalWeekly) / 100;
            if (planPracticeDaysFri > 0) practiceDays['Fri'] = Math.round(planPracticeDaysFri * 100 / totalWeekly) / 100;
            if (planPracticeDaysSat > 0) practiceDays['Sat'] = Math.round(planPracticeDaysSat * 100 / totalWeekly) / 100;

            if (planCompDaysSun > 0) competitiveDays['Sun'] = Math.round(planCompDaysSun * 100 / totalWeekly) / 100;
            if (planCompDaysMon > 0) competitiveDays['Mon'] = Math.round(planCompDaysMon * 100 / totalWeekly) / 100;
            if (planCompDaysTue > 0) competitiveDays['Tue'] = Math.round(planCompDaysTue * 100 / totalWeekly) / 100;
            if (planCompDaysWed > 0) competitiveDays['Wed'] = Math.round(planCompDaysWed * 100 / totalWeekly) / 100;
            if (planCompDaysThu > 0) competitiveDays['Thu'] = Math.round(planCompDaysThu * 100 / totalWeekly) / 100;
            if (planCompDaysFri > 0) competitiveDays['Fri'] = Math.round(planCompDaysFri * 100 / totalWeekly) / 100;
            if (planCompDaysSat > 0) competitiveDays['Sat'] = Math.round(planCompDaysSat * 100 / totalWeekly) / 100;

            let increaseNeeded = target - starting;
            let daysUntilTarget = (targetDate - today) / (1000 * 60 * 60 * 24);
            let increaseNeededPerDay = increaseNeeded / (daysUntilTarget);

            let plannerDay = new Date();
            plannerDay.setDate(today.getDate());
            let dayCounter = 0;

            let trainingDays = [];
            while (plannerDay < targetDate) {
                let dayOfWeek = plannerDay.toLocaleDateString('en-us', { weekday: 'short' });
                let plannerDayLabel = getWholeDay(plannerDay);

                if (practiceDays.hasOwnProperty(dayOfWeek)) {
                    let scaledValue = Math.round((starting + (increaseNeededPerDay * dayCounter)) * practiceDays[dayOfWeek] * 10) / 10;
                    trainingDays.push({ time: plannerDayLabel, value: scaledValue, type: 'practice' });
                } else {
                    trainingDays.push({ time: plannerDayLabel, value: 0, type: 'practice' });
                }

                if (competitiveDays.hasOwnProperty(dayOfWeek)) {
                    let scaledValue = Math.round((starting + (increaseNeededPerDay * dayCounter)) * competitiveDays[dayOfWeek] * 10) / 10;
                    trainingDays.push({ time: plannerDayLabel, value: scaledValue, type: 'competitive' });
                }
                else {
                    trainingDays.push({ time: plannerDayLabel, value: 0, type: 'competitive' });
                }
                dayCounter++;
                plannerDay.setDate(plannerDay.getDate() + 1);
            }

            props.updatePlanned(trainingDays);
        }

            makePlan();
    }, [props, planStartingLoad, planTargetLoad, planTargetDate, planPracticeDaysSun, planPracticeDaysMon, planPracticeDaysTue, planPracticeDaysWed, planPracticeDaysThu, planPracticeDaysFri, planPracticeDaysSat, planCompDaysSun, planCompDaysMon, planCompDaysTue, planCompDaysWed, planCompDaysThu, planCompDaysFri, planCompDaysSat]);

    return <>
        <Grid2 size={12}>
            <Button sx={{position: 'absolute', right: '0px'}} onClick={props.onClose} ><CloseIcon /></Button>
            <SubHeading title={"Goal planner"} />
        </Grid2>
        <Grid2 container spacing={1} sx={{p:1, pt:3}}>
            <Grid2 size={3}>
                <TextField type={"number"} label={`Current`} value={planStartingLoad} onChange={(e) => onPlanStartLoadChange(e.target.value)} fullWidth />
            </Grid2>

            <Grid2 size={3}>
                <TextField type={"number"} label={`Target`} value={planTargetLoad} onChange={(e) => onPlanTargetLoadChange(e.target.value)} fullWidth />
            </Grid2>

            <Grid2 size={6}>
                <TextField InputLabelProps={{ shrink: true }} type={"date"} label="Date" value={planTargetDate} onChange={(e) => onPlanTargetDateChange(e.target.value)} fullWidth />
            </Grid2>

            <Grid2 size={12}>
                <Typography variant={"p"} sx={{fontSize:'12px'}}>Practice days (weighting)</Typography>
                <div>
                    <StyledButtonTop
                      onClick={() => onPlanDaysOfWeekChange(0, 'Sun', 'practice')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planPracticeDaysSun > 0 ? planPracticeDaysSun : '+'
                        }
                    </StyledButtonTop>
                    <StyledButtonTop
                      onClick={() => onPlanDaysOfWeekChange(0, 'Mon', 'practice')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planPracticeDaysMon > 0 ? planPracticeDaysMon : '+'
                        }
                    </StyledButtonTop>
                    <StyledButtonTop
                      onClick={() => onPlanDaysOfWeekChange(0, 'Tue', 'practice')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planPracticeDaysTue > 0 ? planPracticeDaysTue : '+'
                        }
                    </StyledButtonTop>
                    <StyledButtonTop
                      onClick={() => onPlanDaysOfWeekChange(0, 'Wed', 'practice')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planPracticeDaysWed > 0 ? planPracticeDaysWed : '+'
                        }
                    </StyledButtonTop>
                    <StyledButtonTop
                      onClick={() => onPlanDaysOfWeekChange(0, 'Thu', 'practice')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planPracticeDaysThu > 0 ? planPracticeDaysThu : '+'
                        }
                    </StyledButtonTop>
                    <StyledButtonTop
                      onClick={() => onPlanDaysOfWeekChange(0, 'Fri', 'practice')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planPracticeDaysFri > 0 ? planPracticeDaysFri : '+'
                        }
                    </StyledButtonTop>
                    <StyledButtonTop
                      onClick={() => onPlanDaysOfWeekChange(0, 'Sat', 'practice')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planPracticeDaysSat > 0 ? planPracticeDaysSat : '+'
                        }
                    </StyledButtonTop>
                </div>
                <div style={{fontWeight: 'bold'}}>
                    <div style={{fontSize:'14px', width: '43px', display: 'inline-block'}}>SU</div>
                    <div style={{fontSize:'14px', width: '43px', display: 'inline-block'}}>MO</div>
                    <div style={{fontSize:'14px', width: '43px', display: 'inline-block'}}>TU</div>
                    <div style={{fontSize:'14px', width: '43px', display: 'inline-block'}}>WE</div>
                    <div style={{fontSize:'14px', width: '43px', display: 'inline-block'}}>TH</div>
                    <div style={{fontSize:'14px', width: '43px', display: 'inline-block'}}>FR</div>
                    <div style={{fontSize:'14px', width: '43px', display: 'inline-block'}}>SA</div>
                </div>
                <div>
                    <StyledButtonBottom
                      onClick={() => onPlanDaysOfWeekChange(0, 'Sun', 'competitive')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planCompDaysSun > 0 ? planCompDaysSun : '+'
                        }
                    </StyledButtonBottom>
                    <StyledButtonBottom
                      onClick={() => onPlanDaysOfWeekChange(0, 'Mon', 'competitive')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planCompDaysMon > 0 ? planCompDaysMon : '+'
                        }
                    </StyledButtonBottom>
                    <StyledButtonBottom
                      onClick={() => onPlanDaysOfWeekChange(0, 'Tue', 'competitive')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planCompDaysTue > 0 ? planCompDaysTue : '+'
                        }
                    </StyledButtonBottom>
                    <StyledButtonBottom
                      onClick={() => onPlanDaysOfWeekChange(0, 'Wed', 'competitive')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planCompDaysWed > 0 ? planCompDaysWed : '+'
                        }
                    </StyledButtonBottom>
                    <StyledButtonBottom
                      onClick={() => onPlanDaysOfWeekChange(0, 'Thu', 'competitive')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planCompDaysThu > 0 ? planCompDaysThu : '+'
                        }
                    </StyledButtonBottom>
                    <StyledButtonBottom
                      onClick={() => onPlanDaysOfWeekChange(0, 'Fri', 'competitive')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planCompDaysFri > 0 ? planCompDaysFri : '+'
                        }
                    </StyledButtonBottom>
                    <StyledButtonBottom
                      onClick={() => onPlanDaysOfWeekChange(0, 'Sat', 'competitive')}
                      style={{minWidth: '40px', height: `40px`}}
                    >
                        {
                            planCompDaysSat > 0 ? planCompDaysSat : '+'
                        }
                    </StyledButtonBottom>
                </div>
                <Typography variant={"p"} sx={{fontSize:'12px'}}>Competitive days (weighting)</Typography>
            </Grid2>
        </Grid2>
    </>
}

export default GoalPlanner;
