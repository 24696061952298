
import React, {useState} from 'react';
import ClickOutside from './ClickOutside';
import { getWholeDay } from '../graphCalculator';
import GoalPlanner from "./GoalPlanner";
import Grid2 from "@mui/material/Grid2";
import {Alert, Button, Typography, useTheme} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


function Planner(props) {
    let {
        preDays,
        postDays,
        activities,
        isDirty,
        readOnly,
        currentChronic,
        onSave,
        onClose,
    } = props;

    const theme = useTheme();
    var today = new Date();
    var todayLabel = getWholeDay(today);

    // Contains the activities to plot
    const [editVisible, setEditVisible] = useState(false);
    const [editProperties, setEditProperties] = useState({ top: 0, left: 0 });
    const [currentPlannerDay, setCurrentPlannerDay] = useState(null);

    let defaultTargetDate = new Date();
    defaultTargetDate.setDate(defaultTargetDate.getDate() + 28);
    const [startPlanning, setStartPlanning] = useState(false);


    // If it is mobile view we only want to show 7 days
    // Days before today to show on the calendar
    preDays = props.isMobile ? 7 : preDays;

    // Days after today to show on the calendar
    postDays = props.isMobile ? 7 : postDays;

    const showEditable = (cell, day) => {
        var rect = cell.getBoundingClientRect();
        setEditProperties({ top: rect.top, left: rect.left })
        setEditVisible(true);
        setCurrentPlannerDay(day);
    }

    // We need to plot the pre days and post days
    // We also need the chronic duration before the start of the graph
    let days = preDays + postDays;
    let todayIndex = 1;

    var calendar = [];
    var dayCounter = 0;
    var weekCounter = 0;
    calendar[weekCounter] = [];
    var roundDownWeek = true;

    let plannerDay = currentPlannerDay;

    while (todayIndex <= days) {
        var currentDay = new Date();
        currentDay.setDate(today.getDate() - preDays + todayIndex);

        let currentDayLabel = getWholeDay(currentDay);

        var criteria;
        if (todayIndex < (preDays)) {
            // This is in the past, so only care about actual activities
            criteria = activity => activity.Time === currentDayLabel && !activity.IsPlanned;
        }
        else {
            // This is in the future, so only care about planned activities
            criteria = activity => activity.Time === currentDayLabel && activity.IsPlanned;
        }

        let todayEntry = activities.find(criteria);

        

        // By default, today will contain the planned activity
        // If there is an actual activity, then replace it
        if (todayIndex === preDays) {
            let todayActual = activities.find(activity => activity.Time === currentDayLabel && !activity.IsPlanned);
            if (todayActual && (todayActual.CompetitiveValue > 0 || todayActual.PracticeValue > 0)) {
                todayEntry = todayActual;
            }
        }

        dayCounter = new Date(currentDayLabel).getDay();

        if (roundDownWeek) {
            for (let i = 0; i < dayCounter; i++) {
                calendar[weekCounter][i] = null;
            }
            roundDownWeek = false;
        }

        if (todayEntry) {
            //upcomingPlanned.push(todayEntry);
            calendar[weekCounter][dayCounter] = todayEntry;

            if (currentPlannerDay && todayEntry.Time === currentPlannerDay.Time) {
                plannerDay = todayEntry;
            }
        }
        else {
            //upcomingPlanned.push({ Time: currentDayLabel, PracticeValue: 0, CompetitiveValue: 0, IsPlanned: todayIndex > (preDays + 1) });
            calendar[weekCounter][dayCounter] = { Time: currentDayLabel, PracticeValue: 0, CompetitiveValue: 0, IsPlanned: todayIndex > (preDays) };
        }

        if (dayCounter === 6) {
            weekCounter++;
            calendar[weekCounter] = [];
            dayCounter = 0;
        }
        else {
            dayCounter++;
        }

        todayIndex++;
    }

    const valueEntered = (value, day, type) => {
        props.updatePlanned([{ value, time: day.Time, type }]);
    }

    var editingBox = <ClickOutside onClick={() => { setEditVisible(false); }}>
        <div style={{ zIndex: "1", position: 'fixed', top: editProperties.top - 30, left: editProperties.left - 45 }}>
            <div style={{ border: '1px solid #CCCCCC', background: '#333', filter: 'drop-shadow(0 0 10px #666666)', borderRadius: '10px', padding: '3px', color:theme.palette.common.white }}>
                <div><strong>{plannerDay?.Time}</strong></div>
                <div style={{fontSize: '10px'}}>Add planned activity</div>
                <input type="number" value={plannerDay?.PracticeValue} style={{ textAlign: "center", color: "#999999", fontWeight: "bold", margin: "10px", width: "50px", fontSize: "16px" }} onChange={(e) => valueEntered(e.target.value, plannerDay, "practice")} />
                <input type="number" value={plannerDay?.CompetitiveValue} style={{ textAlign: "center", color: "#999999", fontWeight: "bold", margin: "10px", width: "50px", fontSize: "16px" }} onChange={(e) => valueEntered(e.target.value, plannerDay, "competitive")} />
                <div style={{ marginTop: '-8px' }}>
                    <span className="fs-10" style={{ color: "#EEE", fontSize: '10px' }}>Practice&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="fs-10" style={{ color: "#EEE", fontSize: '10px' }}>Comp</span>
                </div>
            </div>
        </div>
    </ClickOutside>

    return (<>
        <Grid2 size={12} container>
            <Grid2 size={12}>
                {(isDirty) &&
                  <Grid2 size={12}>
                      <Alert icon={false} severity="warning" variant={"filled"} sx={{margin:'5px'}}
                             action={<>
                                 <Button color="inherit" size="small" onClick={() => {setStartPlanning(false); onClose();}}>
                                     CANCEL
                                 </Button>&nbsp;
                                 <Button size="small" color={"primary"} variant="contained" onClick={onSave}>
                                     SAVE
                                 </Button></>
                             }>
                          <Typography variant={"subheading2"}>Unsaved changes</Typography>
                      </Alert></Grid2>}
            {editVisible && editingBox}
            {(!props.isMobile && !startPlanning && !readOnly) &&
              <div style={{textAlign: 'left'}}><Button sx={{m:1}} color={"primary"} variant="contained" onClick={() => setStartPlanning(true)}><CalendarMonthIcon sx={{mr:2}}/> Goal planner</Button></div>
            }
            {(!props.isMobile && startPlanning && !readOnly) &&
              <GoalPlanner currentChronic={currentChronic} updatePlanned={props.updatePlanned} onClose={() => setStartPlanning(false)} />
            }
            <div className="table-responsive">
                <table className="table" style={{color: theme.palette.common.white}}>
                    <thead>
                    <tr style={{fontSize: '14px'}}>
                        <th>&nbsp;</th>
                        <th>S</th>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>T</th>
                        <th>F</th>
                        <th>S</th>
                    </tr>
                    </thead>
                    <tbody>
                    {calendar.map((week, w) => {
                        return (<tr key={w}>
                            <td style={{ padding: "0px", fontSize: "10px" }}>{week[0] ? new Date(week[0].Time).toLocaleDateString('en-us', { month: "short" }) : ""}</td>
                            {week.map((day, d) => {
                                return <PlannerCell key={`week-${w}-day-${d}`} day={day} currentDay={todayLabel} showEditable={showEditable} onAddActivity={props.onAddActivity} isMobile={props.isMobile} readOnly={readOnly} />
                            })
                            }
                        </tr>);
                    })
                    }
                    </tbody>
                </table>
            </div>
            </Grid2>
        </Grid2>
    </>)
}

export default Planner;

function PlannerCell(props) {
    const handlePastClick = (day) => {
        if (props.readOnly) return;
        if (day.IsPlanned){
            day.IsPlanned = false;
        }
        props.onAddActivity(day)
    }

    const handleFutureClick = (target, day) => {
        if (props.readOnly) return;
        props.isMobile ? props.onAddActivity(day) : props.showEditable(target, day);
    }

    if (!props.day) {
        return (<td style={{ padding: "0px", fontSize: "16px", verticalAlign: "inherit" }}>&nbsp;</td>)
    }

    let bgStyle = { backgroundColor: "#333333" };
    let total = Math.round((props.day.CompetitiveValue + props.day.PracticeValue) * 10) / 10;
    let cell = total > 0 ?
        <div style={{ marginVertical: "5px" }} onClick={() => handlePastClick(props.day)}>
            <div style={{fontSize: "16px", color: props.day.IsPlanned ? "#FFFFFF" : "#66cc66", fontWeight: "bold" }}>
                {total}</div>
            <div style={{ fontSize: "8px" }}>({props.day.PracticeValue} / {props.day.CompetitiveValue})</div>

        </div> :
        <div style={{ marginVertical: "5px" }} onClick={() => handlePastClick(props.day)}>
            <div style={{fontSize: "16px", color: "#FFFFFF", fontWeight: "bold", padding: "5px", opacity: '0.3' }}>
                {!props.readOnly ? <i className="material-icons fs-16">add_circle</i> : <div>&nbsp;</div>}
            </div>
        </div>

    // If the date is in the future, then show a different color, plus adding opens the inline dialog
    if (props.day.Time > props.currentDay) {
        bgStyle = { backgroundColor: "#666666" }
        cell = total > 0 ? <div style={{ marginVertical: "5px" }} onClick={(e) => handleFutureClick(e.currentTarget, props.day)}>
            <div style={{fontSize: "16px", color: "#FFFFFF", fontWeight: "bold" }}>{total}</div>
            <div style={{ fontSize: "8px" }}>({props.day.PracticeValue} / {props.day.CompetitiveValue})</div>
        </div> :
            <div style={{ marginVertical: "5px" }} onClick={(e) => handleFutureClick(e.currentTarget, props.day)}>
                <div style={{fontSize: "16px", color: "#FFFFFF", fontWeight: "bold", padding: "5px", opacity: '0.3' }}>
                    {!props.readOnly ? <i className="material-icons fs-16">add_circle</i> : <div>&nbsp;</div>}
                </div>
            </div>
    }

    return (<td style={{ padding: "0px", fontSize: "12px", borderLeft: "1px solid #EEEEEE", verticalAlign: "inherit", ...bgStyle }}>
        <div style={{ padding: "0px", color: "#999999", textAlign: "right", paddingRight: "4px", marginBottom: "-14px", fontSize: "10px" }}>{new Date(props.day.Time).toLocaleDateString('en-us', { day: "numeric" })}</div>
        <div>{cell}</div>
    </td>);
}