export const getGraphPoints = (preDays, postDays, startingLoad, activities) => {
    const chronicDurationDays = 28;
    const acuteDurationDays = 7;
    let maxValue = 0;

    let acutePoints = [];
    let chronicPoints = [];
    var alertPoints = [];
    var flagPoints = [];
    var commentPoints = [];

    let historicalPlannedAcutePoints = [];
    let historicalPlannedChronicPoints = [];

    let currentAcute = [];
    let currentChronic = [];

    let activityPoints = [];

    let currentHistoricalAcute = [];
    let currentHistoricalChronic = [];

    let dataLabels = [];

    var alertActive = false;
    var previousDiff = 0;

    // We need to plot the pre days and post days
    // We also need to include the chronic duration before the start of the graph to calculate
    // chronic load using the initial load
    let days = chronicDurationDays + preDays + postDays;
    let todayIndex = 1;

    let todayChronic = startingLoad;

    var today = new Date();

    var todayLabel = today.toLocaleDateString('en-us', { month: "short", day: "numeric" });

    // Get the first activity if there is one
    let firstActual = activities.find((act) => !act.IsPlanned || act.IsPlanned);

    // Loop through all the days, starting at the start of the graph, minus the chronic duration 
    while (todayIndex <= days) {
        // Get the day we are plotting (todayIndex increases this by a day each time)
        var currentDay = new Date();
        currentDay.setDate(today.getDate() - preDays - chronicDurationDays + todayIndex);
        let currentDayLabel = getWholeDay(currentDay);

        var criteria;
        if (todayIndex <= (preDays + chronicDurationDays)) {
            // This is in the past or today, so only care about actual activities
            criteria = activity => activity.Time === currentDayLabel && !activity.IsPlanned;
        }
        else {
            // This is in the future, so only care about planned activities
            criteria = activity => activity.Time === currentDayLabel && activity.IsPlanned;
        }

        // We need to work out whether we are at a point in time before the first activity was logged
        // because if so, the initial load needs to make part of the calculation

        // Assume we are before the first activity
        let beforeFirstActivity = true;
        if (firstActual) {
            // If the first activity has been logged, see if we are before it or not
            let firstActualDate = new Date(firstActual.Time);
            beforeFirstActivity = (firstActualDate.getTime() - currentDay.getTime()) > 0;
        }

        // This value gets added to the chronic load if there is no activity for the day
        let emptyValue = 0;

        // If we are before the first activity, the daily load needs to be added
        // (which is 1/7 of the weekly load)
        if (beforeFirstActivity) {
            emptyValue = startingLoad / 7;
        }

        // Find if there is an activity logged for this day
        let todayEntry = activities.find(criteria);
        let todayPlannedEntry = activities.find(activity => activity.Time === currentDayLabel && activity.IsPlanned);

        // If it is the current day, and there is no actual activity, use the planned activity
        if(todayIndex === (preDays + chronicDurationDays) && !todayEntry && todayPlannedEntry){
            // Commenting this out as we only want the actual activity on the graph
            //todayEntry = todayPlannedEntry;
        }

        // If an activity is found, add it to the current acute and chronic loads
        // If not, either add zero, or the daily portion of the initial load
        currentAcute.push(todayEntry ? todayEntry.PracticeValue + todayEntry.CompetitiveValue : 0);
        currentChronic.push(todayEntry ? todayEntry.PracticeValue + todayEntry.CompetitiveValue : emptyValue);

        currentHistoricalAcute.push(todayPlannedEntry ? todayPlannedEntry.PracticeValue + todayPlannedEntry.CompetitiveValue : 0);
        currentHistoricalChronic.push(todayPlannedEntry ? todayPlannedEntry.PracticeValue + todayPlannedEntry.CompetitiveValue : emptyValue);

        // We need exactly the acute days (7) worth of data
        if (currentAcute.length > acuteDurationDays) {
            currentAcute.shift();
            currentHistoricalAcute.shift()
        }

        // We need exactly the chronic days (28) worth of data
        if (currentChronic.length > chronicDurationDays) {
            currentChronic.shift();
            currentHistoricalChronic.shift();
        }

        if (todayIndex > chronicDurationDays) {
            // If today is greater than the chronicDurationDays buffer, then we need to start plotting the graph

            // The acute value is the sum of the acute array - it should only have the most recent 7 days in it
            let acuteVal = currentAcute.reduce((total, item) => total + item);
            let plannedAcuteVal = currentHistoricalAcute.reduce((total, item) => total + item);

            // The chronic value is the sum of the chronic array divided by 4 - it should only have the most recent 28 days in it
            let chronicVal = currentChronic.reduce((total, item) => total + item) / 4;
            let plannedChronicVal = currentHistoricalChronic.reduce((total, item) => total + item) / 4;

            let dateLabel = new Date(currentDayLabel).toLocaleDateString('en-us', { month: "short", day: "numeric" });

            // Calculate the difference between the acute and chronic values
            var diffPercent = getDiffPercent(acuteVal, chronicVal);

            // This block makes sure that an alert is not given for every day, only when it moves from a safe zone to an alert zone
            if (Math.abs(diffPercent) <= 20 || (diffPercent <= 0 && previousDiff > 0) || (previousDiff <= 0 && diffPercent > 0)) {
                alertActive = false;
            }

            if (Math.abs(diffPercent) > 20 && !alertActive) {
                alertPoints.push({ day: dateLabel, point: acuteVal });
                alertActive = true;
            }

            if (todayEntry && todayEntry.Flag) {
                flagPoints.push({ day: dateLabel, point: acuteVal, flag: todayEntry.Flag });
            }

            if (todayEntry)
            {
                if (todayIndex <= (preDays + chronicDurationDays)){
                    activityPoints.push(todayEntry.PracticeValue + todayEntry.CompetitiveValue);
                } else {
                    activityPoints.push(0);
                }

                if (todayEntry.Comment) {
                    commentPoints.push({ day: dateLabel, point: acuteVal, comment: todayEntry.Comment });
                }

                if (todayEntry.Flag) {
                    flagPoints.push({ day: dateLabel, point: acuteVal, flag: todayEntry.Flag });
                }
            } else {
                activityPoints.push(0);
            }

            // Push the graph points to these arrays so they can be sent to the graph
            previousDiff = diffPercent;
            acutePoints.push(acuteVal);
            historicalPlannedAcutePoints.push(plannedAcuteVal);
            chronicPoints.push(chronicVal);
            historicalPlannedChronicPoints.push(plannedChronicVal);
            dataLabels.push(dateLabel);

            if (acuteVal > maxValue) {
                maxValue = acuteVal;
            }

            if(todayLabel === dateLabel){
                todayChronic = chronicVal;
            }
        }

        todayIndex++;
    }

    return {
        acutePoints, 
        chronicPoints, 
        historicalPlannedAcutePoints, 
        historicalPlannedChronicPoints, 
        dataLabels,
        activityPoints,
        flagPoints,
        commentPoints,
        todayLabel,
        todayChronic,
        postDays,
    }
}

export const getDiffPercent = (acute, chronic) => {
    if (chronic === 0) {
        return 100;
    }

    return Math.round(((acute - chronic) / chronic * 100), 0);
}

export const getWholeDay = (date) => {
    var newDate = new Date(date);
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(newDate);
    let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(newDate);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate);
    const wholeDay = `${year}-${month}-${day}`;
    return wholeDay;
}