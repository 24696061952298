import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts'
import useScreenSize from '../hooks/useScreenSize';
import { getDiffPercent } from '../graphCalculator';

const generateColors = (acutePoints, chronicPoints) => {
    return acutePoints.map((d, idx) => {
        let chronicPt = chronicPoints[idx];
        let color = "#00E396";

        if (chronicPt){
            var diffPercent = getDiffPercent(d, chronicPt);

            if (Math.abs(diffPercent) > 40) {
                color = "#FF0000";
            }
            else if (Math.abs(diffPercent) > 20) {
                color = "#FEB019";
            }
        }
        return {
            offset: idx / acutePoints.length * 100,
            color,
            opacity: 1
        }
    })
}

const generateBaseColors = (points, color) => {
    return points.map((d, idx) => {
        return {
            color,
            opacity: 1
        }
    })
}

const getGraphPoints = (flagPoints, commentPoints) => {
    var graphPoints = [];

    for (let point of flagPoints) {
        let color = "#14D267";
        if (point.flag === "Injured") {
            color= "red";
        }
        if (point.flag === "Sore") {
            color="orange";
        }

        graphPoints.push({
            x: point.day,
            y: point.point,
            marker: {
                size: 8,
                fillColor: color,
                strokeColor: "white",
                radius: 2,
              },
        });
    }

    for (let point of commentPoints) {
        let flagPoint = graphPoints.find((x) => x.x === point.day);

        graphPoints.push({
            x: point.day,
            y: point.point,
            marker: {
                size: 10,
                fillColor: flagPoint ? flagPoint.marker.fillColor : '#fff',
                strokeColor: flagPoint ? flagPoint.marker.strokeColor : '#666666',
                shape: "square",
              },
        });
    }

    return graphPoints;
}

function ActivityGraph(props) {
    const screenSize = useScreenSize();

    // Hide planned load by default so the graph isn't too crowded
    useEffect(() => {
        const chart = ApexCharts.getChartByID('activityChart')
        chart.hideSeries('Planned acute')
        chart.hideSeries('Planned chronic')
    }, []);

    let chartOptions = {
        chart: {
            id: 'activityChart',
            type: "line",
            dropShadow: {
                enabled: true,
                color: '#CCCCCC',
                top: 10,
                left: 7,
                blur: 5,
                opacity: 0.2
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: [generateColors(props.data.acutePoints, props.data.chronicPoints), generateBaseColors(props.data.chronicPoints, "#008FFB"), generateBaseColors(props.data.historicalPlannedAcutePoints, "#00E396"), generateBaseColors(props.data.historicalPlannedChronicPoints, "#008FFB"), generateBaseColors(props.data.activityPoints, "#FFFFFF66")] ,
            }
        },
        annotations: {
            xaxis: [
                {
                    x: props.data.todayLabel,
                    borderColor: '#CCCCCC',
                    label: {
                        orientation: 'horizontal',
                        style: {
                            color: '#333333',
                            background: '#F4D03F'
                        },
                        text: 'Today'
                    }
                }],
            points: getGraphPoints(props.data.flagPoints, props.data.commentPoints)
        },
        colors: ['#00FF00', '#008FFB', '#00E396', '#008FFB', '#FFFFFF99'],
        legend: {
            labels: {
                colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
            },
        },
        forecastDataPoints: {
            count: props.data.postDays
        },
        stroke: {
            width: [4, 4, 1, 1],
            curve: 'smooth'
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: 'category',
            categories: props.data.dataLabels,
            tickAmount: 7,
            axisTicks: {
                color: '#cccccc',
            },
            labels: {
                style: {
                    colors: '#FFFFFF',
                }
            }
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let activityPoint = series[4][dataPointIndex];
                let commentPoint = props.data.commentPoints.find((x) => x.day === props.data.dataLabels[dataPointIndex]);
                let flagPoint = props.data.flagPoints.find((x) => x.day === props.data.dataLabels[dataPointIndex]);
                let html = '<div class="p-1" style="width: 200px; color:#FFF; background-color:#333;">' +
                    '<div class="row"><div class="col-6" style="font-size: 16px"><strong>' + props.data.dataLabels[dataPointIndex] + '</strong></div>' +
                    '<div class="col-6 fs-12">' + (flagPoint ? flagPoint.flag : "") + '</div></div>';

                if (activityPoint) {
                    html += '<div class="row fs-12 p-1"><div class="col-4"><strong>Daily:</strong>' + activityPoint + '</div></div>';
                }
                html += '<div class="row fs-12 p-1"><div class="col-4"><strong>Acute:</strong>' + (Math.round(series[0][dataPointIndex] * 10) /10) + '</div>' +
                    '<div class="col-4"><strong>Chronic:</strong>' + (Math.round(series[1][dataPointIndex] * 10) / 10) + '</div></div>' +
                    '<div class="row fs-12 p-1"><div class="col-4"><strong>Difference:</strong>' + getDiffPercent(series[0][dataPointIndex], series[1][dataPointIndex]) + '%</div></div>'
                    
                if (commentPoint) {
                    html += '<div class="row fs-12 "><div class="col-12 text-left text-wrap">' + commentPoint.comment + '</div></div><br />';
                }

                return html + '</div>'
            }
        },
        yaxis: {
            decimalsInFloat: 1,
            axisTicks: {
                color: '#FFFFFF',
            },
            labels: {
                style: {
                    colors: '#FFFFFF',
                }
            },
        },
        responsive: [{
            breakpoint: 540,
            options: {
                chart: {
                    height: `${Math.round(screenSize.height / 2)}px`,
                }
            },
        }]
    };

    let chartSeries = [
        {
            name: "Acute",
            data: props.data.acutePoints,
            type: "line",
        },
        {
            name: "Chronic",
            data: props.data.chronicPoints,
            type: "line",
        },
        {
            name: "Planned acute",
            data: props.data.historicalPlannedAcutePoints,
            type: "line",
        },
        {
            name: "Planned chronic",
            data: props.data.historicalPlannedChronicPoints,
            type: "line",
        },
        {
            name: "Activities",
            data: props.data.activityPoints,
            type: "column",
        }
    ];


    return (<ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="line"
    />
    )
}

export default ActivityGraph;