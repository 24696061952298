import React from 'react';
import {auth0} from '../environment';
import {Button} from "@mui/material";

const createAccount = () => {
  window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f&screen_hint=signup`;
}

const loginClick = () => {
  window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f`;
  }

  function Login() {
  return (
        <div style={{height: '100vh', paddingTop: 'calc(50vh - 250px)', backgroundColor: 'black'}}>
              <img src="../assets/img/x-tracked-logo-full.png" alt="logo" />
              <p>v1.2.11</p>

              <div style={{marginTop: "30px"}}>
                <Button size="large" variant="contained" onClick={loginClick}  >
                  Login
                        </Button>
              </div>
              <p style={{color: '#eeeeee', marginTop: '20px'}}>Don't have an account? <a className="btn-link" href="#" onClick={createAccount}>Create Account</a> </p>

    </div>
  );
}

export default Login;
